<template>
  <div class="clients" id="body">
    <div class="section-title">
							<h1>Hier könnte auch Ihr Logo stehen !</h1>
							<div class="header-border grey" />
    
			</div>
  <div class="container">
          <p>An dieser Stelle bedanken wir uns bei unseren Kunden und Partnerunternehmen für die vertrauensvolle Zusammenarbeit und freuen uns auf weitere spannende und erfolgreiche Projekte.</p>
      <div class="brands">
        <div class="row">
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/drifte.jpg" alt="Drifte">
            </div>
            <!--<div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/alfonsmanagement.jpg" alt="Drifte">
            </div>-->
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/edamo.jpg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/edekakhan.jpg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/enviropro.jpg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/fitnessstudio2000.jpg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/funke.jpg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/kangaroo_logo.svg" alt="Drifte">
            </div>
            <!--<div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/hrexperts.jpg" alt="Drifte">
            </div>-->
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/Prolight.png" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/return.png" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/urbano.png" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/Gebas.png" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/Bruells.png" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/indulighting.jpg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/martinkoetter.jpg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/paulzen.jpg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/smartworx.jpg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/sweeren.jpg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/tivita.jpg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/tkd.jpg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/ml-gruppe.svg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/ttigmbh.jpg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/zahnzentrummoers.jpg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/sector27.svg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/logo_telefonica_blue.png" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/NT-LOGO.jpg" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/syno2.png" alt="Drifte">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/Triopt-Group.png" alt="Triopt Group">
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-lg-3 box">
                <img src="../assets/images/brands/DWE_Logo_1920px.png" alt="DWE">
            </div>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "clients",
  components: {
    
  },
  methods: {
  }
};
</script>

